<template>
  <popupContainer class="like-dislike-popup-container">
    <div :style="labelStyles" class="label">
      {{ popupData.textLikeDislike.name }}
    </div>
    <div class="like-dislike-icons">
      <div
        class="icon-container"
        @click="popupData.callback(popupData.likeName)"
      >
        <img class="like like-dislike-btn" :src="popupData.likeIcon" />
      </div>

      <div class="line"></div>
      <div
        class="icon-container"
        @click="popupData.callback(popupData.dislikeName)"
      >
        <img class="dislike like-dislike-btn" :src="popupData.dislikeIcon" />
      </div>
    </div>
  </popupContainer>
</template>

<script>
import popupContainer from "@/components/PopupContainer";
import { mapGetters } from "vuex";
export default {
  name: "SvPopupLikeDislike",
  props: ["popupData"],
  components: {
    popupContainer
  },
  computed: {
    ...mapGetters(["activeStep"]),
    labelStyles() {
      return {
        fontFamily: this.popupData.fontFamily,
        background: this.popupData.textBackgroundColor,
        color: this.popupData.textColor
      };
    }
  }
};
</script>

<style>
.like-dislike-popup-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  padding: 40px 10px;
  box-sizing: border-box;
}

.like-dislike-popup-container {
  display: flex;
  height: 100%;
  width: 100%;
}
.like-dislike-btn {
  display: flex;
  max-width: 100px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.line {
  height: 100%;
  background: #f4f4f4;
  width: 1px;
}
.like-dislike-icons {
  box-sizing: border-box;
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}
.label {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 290px;
  max-width: 250px;
  height: 30px;
  line-height: 30px;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 150px;
  height: 436px;
  max-width: 150px;
  max-height: 436px;
}
.icon-container:active {
  background: #f4f4f4;
}
</style>
